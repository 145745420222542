import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, List, ListItem, Typography, Box, IconButton, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import io from 'socket.io-client';

const socket = io('https://api-polos-hml.matriculas.unifecaf.edu.br', { transports: ['websocket'], withCredentials: true });

function App() {
  const [itemName, setItemName] = useState('');
  const [items, setItems] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      socket.emit('setName', { name: userName });

      socket.on('updateItems', (newItems) => {
        setItems(newItems);
      });

      socket.on('updateUsers', (connectedUsers) => {
        setUsers(connectedUsers);
      });

      socket.emit('getItems');

      return () => {
        socket.off('updateItems');
        socket.off('updateUsers');
      };
    }
  }, [isLoggedIn, userName]);

  const handleAddItem = () => {
    if (itemName) {
      socket.emit('addItem', itemName);
      setItemName('');
    }
  };

  const handleUpdateItem = () => {
    if (editItem && itemName) {
      socket.emit('updateItem', { id: editItem.id, name: itemName });
      setItemName('');
      setEditItem(null);
    }
  };

  const handleDeleteItem = (id) => {
    socket.emit('deleteItem', id);
  };

  const startEdit = (item) => {
    setItemName(item.name);
    setEditItem(item);
  };

  const handleLogin = () => {
    if (userName) {
      setIsLoggedIn(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Usuários Conectados:
        </Typography>
        <List>
          {users.map(user => (
            <ListItem key={user.id}>
              <Typography variant="body1">{user.name}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      {!isLoggedIn ? (
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Digite seu nome"
            variant="outlined"
            fullWidth
            margin="normal"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
          >
            Entrar
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="h4" gutterBottom align="center">
            CRUD de teste
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              label={editItem ? "Atualizar Item" : "Adicionar Item"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={editItem ? handleUpdateItem : handleAddItem}
            startIcon={editItem ? <EditIcon /> : <AddIcon />}
          >
            {editItem ? "Atualizar" : "Adicionar"}
          </Button>
          <Box sx={{ mt: 2 }}>
            <List>
              {items.map((item) => (
                !item.isDeleted && (
                  <React.Fragment key={item.id}>
                    <ListItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: 1,
                        mb: 1,
                        p: 1,
                        bgcolor: '#f5f5f5'
                      }}
                    >
                      <Typography variant="body1">{item.name}</Typography>
                      <Box>
                        <IconButton color="primary" onClick={() => startEdit(item)} aria-label="editar">
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteItem(item.id)} aria-label="excluir">
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                )
              ))}
            </List>
          </Box>
        </>
      )}
    </Container>
  );
}

export default App;
